const orderTicketFragment = `
  fragment TicketNodes on OrderAttendeeTicketPossession {
    ticket {
      id
      name
      price {
        amount
        currencyCode
      }
    }
  }
`;

export default orderTicketFragment;
