import PropTypes from 'prop-types';

import styled from 'styled-components';

import Popover from '@material-ui/core/Popover';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import XtraButton from './XtraButton';
import XtraFlexbox from './XtraFlexbox';
import XtraH3 from './XtraH3';
import XtraText from './XtraText';

const StyledText = styled(XtraText)`
  margin: 24px 0;
  text-align: center;
  font-size: 14px;
`;

const StyledFlexbox = styled(XtraFlexbox)`
  width: 100%;
  padding: 0 6px;
`;

const useStyles = makeStyles({
  paper: {
    maxWidth: '240px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 16px 16px',
  },
});

const XtraConfirmationPopover = ({
  popoverId,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  onCancel,
  onConfirm,
  title,
  content,
  confirmBtnLabel,
  confirmBtnDisabled,
  cancelBtnLabel,
}) => {
  const { t } = useTranslation();
  const resolvedConfirmBtnLabel = confirmBtnLabel || t('general.confirm');
  const resolvedCancelBtnLabel = cancelBtnLabel || t('general.cancel');
  const popoverClasses = useStyles();

  return (
    <Popover
      id={popoverId}
      classes={popoverClasses}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onCancel}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <XtraH3>
        { title }
      </XtraH3>
      <StyledText>
        { content }
      </StyledText>
      <StyledFlexbox justifyContent="space-between">
        <XtraButton onClick={onCancel}>
          { resolvedCancelBtnLabel }
        </XtraButton>
        <XtraButton
          bsStyle="danger"
          onClick={onConfirm}
          disabled={confirmBtnDisabled}
        >
          { resolvedConfirmBtnLabel }
        </XtraButton>
      </StyledFlexbox>
    </Popover>
  );
};

XtraConfirmationPopover.propTypes = {
  popoverId: PropTypes.string.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  anchorOrigin: PropTypes.shape(),
  transformOrigin: PropTypes.shape(),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  confirmBtnLabel: PropTypes.string,
  confirmBtnDisabled: PropTypes.bool,
  cancelBtnLabel: PropTypes.string,
};

XtraConfirmationPopover.defaultProps = {
  anchorEl: null,
  anchorOrigin: null,
  transformOrigin: null,
  onCancel: () => {},
  onConfirm: () => {},
  confirmBtnLabel: null,
  confirmBtnDisabled: false,
  cancelBtnLabel: null,
};

export default XtraConfirmationPopover;
