import { gql } from '@apollo/client';

const fetchFormAttributesQuery = gql`
  query getorderByAccessKey(
    $accessKey: ID!
  ) {
    orderByAccessKey(accessKey: $accessKey) {
      mainForm {
        industriesOptions
        salutations
        countryCodes
      }
    }
  }
`;

export default fetchFormAttributesQuery;
