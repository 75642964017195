import { gql } from '@apollo/client';

const fetchTicketingPage = gql`
  query getorderByAccessKey(
    $accessKey: ID!
    $en: Boolean!
    $zh_hk: Boolean!
    $zh_cn: Boolean!
    $ja: Boolean!
    $ko: Boolean!
    $ru: Boolean!
  ) {
    orderByAccessKey(accessKey: $accessKey) {
      ticketingElement {
        discountCodeEnabled
        ticketItems {
          en: nodes @locale(lang: en) @include(if: $en) {
            ...TicketNodes
          }
          zh_hk: nodes @locale(lang: zh_hk) @include(if: $zh_hk) {
            ...TicketNodes
          }
          zh_cn: nodes @locale(lang: zh_cn) @include(if: $zh_cn) {
            ...TicketNodes
          }
          ja: nodes @locale(lang: ja) @include(if: $ja) {
            ...TicketNodes
          }
          ko: nodes @locale(lang: ko) @include(if: $ko) {
            ...TicketNodes
          }
          ru: nodes @locale(lang: ru) @include(if: $ru) {
            ...TicketNodes
          }
        }
      }
      ticketMaximumPerOrder
    }
  }
  fragment TicketNodes on OrderTicketItem {
    ticket {
      id: ticketId
      details
      name
      price {
        amount
        currencyCode
      }
      remainingQuota
      showRemainingTickets
      salesEndAt
      salesStartAt
      maximumQuantity
      minimumQuantity
      isDefaultTicket
      allowZeroQuantity
    }
  }
`;

export default fetchTicketingPage;
