import { FC } from 'react';
import styled from 'styled-components';
import isRequireSrc from 'registration_form_builder/Image/is_require.svg';

const RequiredDotImage = styled.img`
  margin: 0 8px 7px 0;
`;

const RequiredDot: FC = () => (
  <RequiredDotImage src={isRequireSrc} alt="Required" />
);

export default RequiredDot;
