import { gql } from '@apollo/client';

const setBooleanFieldMutation = gql`
  mutation setBooleanField(
    $orderAccessKey: String!
    $attendeeId: ID!
    $fieldElementId: ID!
    $value: Boolean!
  ) {
    orderFieldSetBooleanValue(
      input: {
        orderAccessKey: $orderAccessKey
        attendeeId: $attendeeId
        fieldElementId: $fieldElementId
        value: $value
      }
    ) {
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      clientMutationId
      success
    }
  }
`;
export default setBooleanFieldMutation;
