import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import classNames from 'classnames';

import styled from 'styled-components';

const XtraToggle = (props) => {
  const { t } = useTranslation();
  const {
    className,
    checked,
    icons = {
      checked: <div className="toggle-label">{t('general.on')}</div>,
      unchecked: <div className="toggle-label">{t('general.off')}</div>,
    },
    disabled,
    onChange,
  } = props;

  const classes = classNames(
    'toggle',
    className,
  );

  return (
    <div className={classes}>
      <Toggle
        checked={checked}
        icons={icons}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

XtraToggle.propTypes = {
  className: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  icons: PropTypes.shape({
    checked: PropTypes.node,
    unchecked: PropTypes.node,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default styled(XtraToggle)`
  height: 24px;

  .react-toggle {
    .react-toggle-track {
      background-color: #A2AAB3;
      font-family: Lato;
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;

      .react-toggle-track-x {
        right: 15px;
      }

      .toggle-label {
        height: 10px;
        line-height: 10px;
      }
    }

    .react-toggle-thumb {
      top: 3px;
      height: 18px;
      width: 18px;
      border: none;
    }

    &.react-toggle--checked {
      .react-toggle-track {
        background-color: #5C8DF3;
      }

      .react-toggle-thumb {
        left: 29px;
      }

      &:hover .react-toggle-track {
        background-color: #5C8DF3;
      }
    }

    &:not(.react-toggle--checked) {
      .react-toggle-thumb {
        left: 3px;
      }

      &:hover .react-toggle-track {
        background-color: #A2AAB3;
      }
    }
  }
`;
