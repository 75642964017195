import { gql } from '@apollo/client';

export default gql`
  query fetchRsvpNoThankYouPageQuery($orderAccessKey: ID!){
    orderByAccessKey(accessKey: $orderAccessKey){
      regform {
        backgroundColor
        themeColor
        locales
        fontFamily
      }
      ... on RsvpOrder {
        status
        rsvpNoThankYouPageViewElement {
          pages {
            nodes {
              elements {
                en: nodes @locale(lang: en) {
                  ...DisplayElementNodes
                }
                zh_hk: nodes @locale(lang: zh_hk) {
                  ...DisplayElementNodes
                }
                zh_cn: nodes @locale(lang: zh_cn) {
                  ...DisplayElementNodes
                }
                ja: nodes @locale(lang: ja) {
                  ...DisplayElementNodes
                }
                ko: nodes @locale(lang: ko) {
                  ...DisplayElementNodes
                }
                ru: nodes @locale(lang: ru) {
                  ...DisplayElementNodes
                }
              }
            }
          }
        }
      }
    }
  }
  fragment DisplayElementNodes on RegformElement {
    id
    ... on RegformBuilderObject {
      typeName: __typename
    }
    ... on RegformImageElement {
      url: imageUrl
    }
    ... on RegformTextElement {
      html
    }
  }
`;
