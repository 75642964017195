import { gql } from '@apollo/client';

const mutationStringWrapper = (orderAccessKey, attendeeId) => (`
  orderRemoveAttendee${orderAccessKey + attendeeId}: orderRemoveAttendee(input:{
    orderAccessKey: "${orderAccessKey}",
    attendeeId: "${attendeeId}"
  }) {
    success
    errors {
      details {
        key
        value
      }
      message
      path
      type
    }
  }
`);

const unapplyDiscountCodeWrapper = (orderAccessKey, discountCode) => (`
  orderUnapplyDiscountCode${orderAccessKey + discountCode}: orderUnapplyDiscountCode(
    input: {
      orderAccessKey: "${orderAccessKey}",
      discountCode: "${discountCode}"
    }
  ) {
    success
    errors {
      details {
        key
        value
      }
      message
      path
      type
    }
  }
`);

const orderRemoveAttendeeMutation = (orderAccessKey, attendeeIds, appliedDiscountCode) => {
  let mutationString = '';
  attendeeIds.forEach((eachAttendeeId) => {
    mutationString += mutationStringWrapper(orderAccessKey, eachAttendeeId);
  });
  if (appliedDiscountCode.length > 0) {
    appliedDiscountCode.forEach((eachDiscountCode) => {
      mutationString += unapplyDiscountCodeWrapper(orderAccessKey, eachDiscountCode);
    });
  }
  mutationString = `
    mutation orderRemoveAttendee {
      orderReleaseQuota(input: { orderAccessKey: "${orderAccessKey}"}) {
        success
        errors {
          details {
            key
            value
          }
          message
          path
          type
        }
      }
      ${mutationString}
    }
  `;
  return gql(mutationString);
};

export default orderRemoveAttendeeMutation;
