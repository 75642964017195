import { gql } from '@apollo/client';

const checkOrderStatusQuery = gql`
  query checkOrderStatusQuery($accessKey: ID!) {
    orderByAccessKey(accessKey: $accessKey) {
      status
    }
  }
`;

export default checkOrderStatusQuery;
