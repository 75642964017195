import { gql } from '@apollo/client';

const checkAttendeeError = gql`
  query checkAttendeeError (
    $accessKey: ID!
  ) {
    orderByAccessKey(accessKey: $accessKey) {
      status
      payments(status: PENDING) {
        nodes {
          uuid
        }
      }
      attendees {
        nodes {
          attendeeId
          errors {
            type
            message
            fieldElementId
            details {
              value
              key
            }
          }
        }
      }
    }
  }
`;

export default checkAttendeeError;
