import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Immutable from 'immutable';

import useMutationLoading from 'helpers/graphql/useMutationLoading';
import useMutationSuccess from 'helpers/graphql/useMutationSuccess';
import XtraGraphContext from 'helpers/graphql/XtraGraphContext';

const XtraGraphContextProvider = ({ children }) => {
  const [mutationLoading, setMutationLoading] = useMutationLoading(false);
  const [mutationSuccess, setMutationSuccess] = useMutationSuccess(false);
  const [errors, setErrors] = useState(Immutable.Map());

  const setErrorsMap = useCallback((errorsArr = [], key = 'root') => {
    const errorsMap = errorsArr.reduce((result, { message, path }) => (
      result.setIn([key, ...path], message)
    ), Immutable.Map());
    setErrors(errorsMap);
  }, []);

  return (
    <XtraGraphContext.Provider
      value={{
        errors,
        setErrors: setErrorsMap,
        mutationLoading,
        setMutationLoading,
        mutationSuccess,
        setMutationSuccess,
      }}
    >
      { children }
    </XtraGraphContext.Provider>
  );
};

XtraGraphContextProvider.propTypes = {
  children: PropTypes.node,
};

XtraGraphContextProvider.defaultProps = {
  children: null,
};

export default XtraGraphContextProvider;
