import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

const backgroundColor = '#484848';
const XtraTooltip = styled(Tooltip)`
  .tooltip-inner {
    font-size: 14px;
    padding: 10px 8px;
    background: ${backgroundColor};
  }

  &.tooltip.top .tooltip-arrow {
    border-top-color: ${backgroundColor};
  }

  &.tooltip.right .tooltip-arrow {
    border-right-color: ${backgroundColor};
  }

  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: ${backgroundColor};
  }

  &.tooltip.left .tooltip-arrow {
    border-left-color: ${backgroundColor};
  }
`;

export default XtraTooltip;
