import { gql } from '@apollo/client';

const validateAccessCodeMutation = gql`
  mutation validateAccessCodeMutation(
    $orderAccessKey: String!
    $accessCode: String!
  ) {
    orderApplyAccessCode(
      input: {
        orderAccessKey: $orderAccessKey
        accessCode: $accessCode
      }
    ) {
      clientMutationId
      success
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
    }
  }
`;
export default validateAccessCodeMutation;
