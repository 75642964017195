import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import XtraH2 from './XtraH2';
import XtraFlexbox from './XtraFlexbox';
import CloseButtonSrc from './images/close_button.svg';
import StartLiveChatSrc from './images/start_live_chat.svg';
import GiveFeedbackSrc from './images/give_feedback.svg';
import SearchHelpCenterSrc from './images/search_help_center.svg';
import QuestionMarkSrc from './images/question_mark.svg';

const xtraHelpButtonContainerHeightByLocalMap = {
  default: '322px',
  ja: '420px',
};

const XtraHelpButtonContainer = styled.div`
  width: ${({ isOpened }) => (isOpened ? '272px' : '108px')};
  height: ${({ isOpened, locale }) => (isOpened ? xtraHelpButtonContainerHeightByLocalMap[locale] ?? xtraHelpButtonContainerHeightByLocalMap.default : '46px')};
  position: fixed;
  padding: 24px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: ${({ isOpened }) => (isOpened ? '4px' : '50%')};
  right: 24px;
  bottom: 24px;
  transition: all 0.3s;
  background: white;
  cursor: ${({ isOpened }) => (isOpened ? 'initial' : 'pointer')};
  display: ${({ isOpened }) => (isOpened ? 'block' : 'inline-block')};
  z-index: 2000;
`;

const upperContainerHeightByLocalMap = {
  default: '147px',
  ja: '195px',
};

const UpperContainer = styled.div`
  width: 100%;
  height: ${({ isOpened, locale }) => (isOpened ? upperContainerHeightByLocalMap[locale] ?? upperContainerHeightByLocalMap.default : '100%')};
  background: #5C8DF3;
  border-radius: ${({ isOpened }) => (isOpened ? '4px 4px 0 0' : '23px')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const StyledXtraH2 = styled(XtraH2)`
  color: white;
`;

const ButtonIcon = styled.img`
  padding: 0 8px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 12px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0.5px solid #DBDEE2;
  background: white;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
`;

const CloseButton = styled.img`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 4px;
`;

const EnlargedContainer = styled(XtraFlexbox)`
  width: 100%;
  height: 100%;
  transition: all 0.3s, scale 0.5s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  transform: ${({ state }) => (state === 'entered' ? 'scale(1)' : 'scale(0)')};
`;

const HelpTextContainer = styled(XtraFlexbox)`
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'entered' ? 'flex' : 'none')};
`;

const HelpText = styled.span`
  padding-right: 8px;
`;

const XtraHelpButton = ({
  showHelpButton,
  toggleShowHelpButton,
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <XtraHelpButtonContainer
      isOpened={showHelpButton}
      onClick={() => !showHelpButton && toggleShowHelpButton(!showHelpButton)}
      locale={locale}
    >
      <UpperContainer isOpened={showHelpButton} locale={locale} />
      <Transition in={showHelpButton} timeout={0}>
        {(state) => (
          <EnlargedContainer justifyContent="space-around" direction="column" state={state}>
            <CloseButton
              src={CloseButtonSrc}
              onClick={(e) => { e.stopPropagation(); toggleShowHelpButton(!showHelpButton); }}
            />
            <TextContainer>
              <StyledXtraH2>
                {t('menu.hi_there')}
                <span role="img" aria-label="wave">&nbsp;&#128075;</span>
              </StyledXtraH2>
            </TextContainer>
            <TextContainer>
              <StyledXtraH2>{t('attendees.here_to_help')}</StyledXtraH2>
            </TextContainer>
            <ButtonContainer
              onClick={() => {
                // TODO: Relink Zopim
                // $zopim(() => {
                //   const { user } = gon;
                //   $zopim.livechat.setName(user ? user.name : t('general.anonymous', { locale }));
                //   $zopim.livechat.setEmail(user ? user.email : '');
                //   $zopim.livechat.setLanguage(locale);
                //   $zopim.livechat.window.show();
                // });
              }}
            >
              <ButtonIcon src={StartLiveChatSrc} />
              {t('menu.live_chat')}
            </ButtonContainer>
            <ButtonContainer id="feedback-button">
              <ButtonIcon src={GiveFeedbackSrc} />
              {t('menu.feedback_button')}
            </ButtonContainer>
            <ButtonContainer
              onClick={() => {
                window.open('https://support.eventxtra.com');
              }}
            >
              <ButtonIcon src={SearchHelpCenterSrc} />
              {t('menu.help_center')}
            </ButtonContainer>
          </EnlargedContainer>
        )}
      </Transition>
      <Transition in={!showHelpButton} timeout={0}>
        {
          (state) => (
            <HelpTextContainer state={state} justifyContent="center">
              <ButtonIcon src={QuestionMarkSrc} />
              <HelpText>{t('general.help')}</HelpText>
            </HelpTextContainer>
          )
        }
      </Transition>
    </XtraHelpButtonContainer>
  );
};

XtraHelpButton.propTypes = {
  showHelpButton: PropTypes.bool.isRequired,
  toggleShowHelpButton: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapState = (state) => ({
  showHelpButton: state.showHelpButton,
  locale: state.locale,
});

const mapDispatch = ({
  showHelpButton: { toggleShowHelpButton },
}) => ({
  toggleShowHelpButton,
});

export default connect(mapState, mapDispatch)(XtraHelpButton);
