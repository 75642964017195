import { gql } from '@apollo/client';

const fetchFormQuery = gql`
  query getorderByAccessKey(
    $accessKey: ID!
    $en: Boolean!
    $zh_hk: Boolean!
    $zh_cn: Boolean!
    $ja: Boolean!
    $ko: Boolean!
    $ru: Boolean!
    $first: Int!
    $after: String!
  ) {
    orderByAccessKey(accessKey: $accessKey) {
      mainForm {
        pages {
          nodes {
            elements(first: $first, after: $after) {
              pageInfo {
                endCursor
                hasNextPage
              }
              en: nodes @locale(lang: en) @include(if: $en){
                ...FormElementNodes
              }
              zh_hk: nodes @locale(lang: zh_hk) @include(if: $zh_hk){
                ...FormElementNodes
              }
              zh_cn: nodes @locale(lang: zh_cn) @include(if: $zh_cn){
                ...FormElementNodes
              }
              ja: nodes @locale(lang: ja) @include(if: $ja){
                ...FormElementNodes
              }
              ko: nodes @locale(lang: ko) @include(if: $ko){
                ...FormElementNodes
              }
              ru: nodes @locale(lang: ru) @include(if: $ru){
                ...FormElementNodes
              }
            }
          }
        }
      }
    }
  }

  fragment LogicNodes on RegformBuilderObject {
    id
    ... on RegformBuilderObject {
      typeName: __typename
    }
    ... on RegformLogic {
      id
      condition {
        ...ConditionNodes
      }
    }
  }
  fragment ConditionNodes on RegformBuilderObject {
    id
    ... on RegformBuilderObject {
      typeName: __typename
    }
    ... on RegformCompoundCondition {
      subConditions {
        nodes {
          id
          typeName: __typename
          ... on RegformChoiceSelectedAtomicCondition {
            choiceElement {
              id
              typeName: __typename
              choice {
                id
              }
              mcQuestion {
                ... on RegformElement {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
  fragment FormElementNodes on RegformBuilderObject {
    id
    ... on RegformBuilderObject {
      typeName: __typename
    }
    ... on RegformElement {
      isLogicalAffector
      hasAffectorChildren
      logics {
        nodes {
          ...LogicNodes
        }
      }
    }
    ... on RegformFieldElement {
      hintText
      hintHtml
      hintEditorContent
      labelText
      required
    }
    ... on RegformTextFieldElement {
      placeholderText
    }
    ... on RegformImageElement {
      url: imageUrl
    }
    ... on RegformTextElement {
      html
    }
    ... on RegformCheckboxQuestionFieldElement {
      checkedByDefault
    }
    ... on RegformWaiverQuestionFieldElement {
      checkedByDefault
      html
    }
    ... on RegformChoiceFieldElement {
      availableChoices {
        choices: nodes {
          value: choiceId
          label: choiceText
        }
      }
    }
  }
`;

export default fetchFormQuery;
