import { gql } from '@apollo/client';

const orderAddAttendeeBatchMutation = (orderAccessKey, count) => gql(`
  mutation orderAddAttendeeBatch {
    orderAddAttendeeBatch(input:{ orderAccessKey: "${orderAccessKey}", count: ${count} }){
      attendees{
        attendeeId
      }
      success
      errors {
        type
      }
    }
  }
`);

export default orderAddAttendeeBatchMutation;
