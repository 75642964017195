import { gql } from '@apollo/client';

const orderLockQuota = gql`
  mutation orderLockQuota($orderAccessKey: String!) {
    orderLockQuota(input: { orderAccessKey: $orderAccessKey }) {
      success
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      order {
        expiresAt
      }
    }
  }
`;

export default orderLockQuota;
