import {
  ApolloClient, InMemoryCache, ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createTransformerLink } from 'apollo-client-transform';
import { createUploadLink } from 'apollo-upload-client';
import createRetryLink from './createRetryLink';
import createOfflineLink from './createOfflineLink';
import dataIdFromObject from './relayStyledDataIdFromObject';
import createErrorLink from './createErrorLink';
import createVariableSerializationLink from './createVariableSerializationLink';
import transformersMapping from './transformersMapping';
import serializersMapping from './serializersMapping';
import 'isomorphic-fetch';

const xtraGraphQLEndpointURL = process.env.NEXT_PUBLIC_XTRA_GRAPH_ENDPOINT;

let link = ApolloLink.from([
  createErrorLink(),
  createTransformerLink(transformersMapping) as unknown as ApolloLink,
  createVariableSerializationLink(serializersMapping),
  createOfflineLink() as unknown as ApolloLink,
  createRetryLink() as unknown as ApolloLink,
  createUploadLink({
    uri: xtraGraphQLEndpointURL,
  }) as unknown as ApolloLink,
]);

const cache = new InMemoryCache({
  dataIdFromObject,
});

export const xtraClient = (personalAccessToken?: string) => {
  let authLink;
  if (personalAccessToken) {
    authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${personalAccessToken}`,
      },
    }));
    link = authLink.concat(link);
  }
  return new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
    ssrMode: typeof window !== 'undefined',
  });
};

export default xtraClient;
