import DatetimeTransformer from './transformers/DatetimeTransformer';

const transformers = {
  Party: {
    startAt: DatetimeTransformer,
    endAt: DatetimeTransformer,
    createdAt: DatetimeTransformer,
    updatedAt: DatetimeTransformer,
  },
  PersonalAccessToken: {
    createdAt: DatetimeTransformer,
    expiresAt: DatetimeTransformer,
  },
};

export default transformers;
