import { gql } from '@apollo/client';

const fetchRegformMetaQuery = gql`
  query getorderByAccessKey($accessKey: ID!, $locale: LocaleEnum!) {
    orderByAccessKey(accessKey: $accessKey) @locale(lang: $locale) {
      regform {
        eventName
      }
    }
  }
`;

export default fetchRegformMetaQuery;
