import { RetryLink } from 'apollo-link-retry';

const retryIf = (error) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const createRetryLink = (): RetryLink => new RetryLink({
  delay: {
    initial: 2000,
    max: 20000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

export default createRetryLink;
