import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const SIZES = {
  normal: {
    height: '32px',
    padding: '8px 16px',
  },
  large: {
    height: '40px',
    padding: '12px 24px',
  },
};

const StyledButton = styled(Button)`
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  line-height: 16px;
  letter-spacing: 0.3px;
  outline: none !important;
  height: ${({ size }) => SIZES[size].height};
  padding: ${({ size }) => SIZES[size].padding};
`;

export const TYPES = ['button', 'reset', 'submit'];

export const BS_STYLES = ['default', 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'link'];

const XtraButton = ({
  className,
  style,
  type,
  size,
  bsStyle,
  onClick,
  href,
  id,
  target,
  disabled,
  active,
  block,
  children,
}) => {
  const classes = classNames('xtra-button', className);
  style = { ...style };
  return (
    <StyledButton
      className={classes}
      style={style}
      type={type}
      size={size}
      variant={bsStyle}
      onClick={onClick}
      id={id}
      href={href}
      target={target}
      disabled={disabled}
      active={active}
      block={block}
    >
      {children}
    </StyledButton>
  );
};

XtraButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.oneOf(TYPES),
  size: PropTypes.oneOf(Object.keys(SIZES)),
  bsStyle: PropTypes.oneOf(BS_STYLES),
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.node,
};

XtraButton.defaultProps = {
  className: '',
  style: {},
  type: TYPES[0],
  size: 'normal',
  bsStyle: BS_STYLES[0],
  onClick: null,
  href: null,
  target: null,
  id: undefined,
  disabled: false,
  active: false,
  block: false,
  children: null,
};

export default XtraButton;
