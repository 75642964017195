import React from 'react'

import {
  Dropdown
} from 'react-bootstrap'

import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css'

import styled from 'styled-components'
import classNames from 'classnames'

import XtraInputLabel from './XtraInputLabel'
import XtraErrorText from './XtraErrorText'

const styles = {
	"dateTime": {
		"normal": {
			"width": "100%",
			"height": 40,
			"border": "2px solid #DBDEE2",
			"fontSize": 14,
			"textAlign": "left"
		},
		"invalid": {
			"width": "100%",
			"height": 40,
			"border": "2px solid #E2654D",
			"fontSize": 14,
			"textAlign": "left"
		}
	}
};

const XtraDatetimeField = (props) => {
  const {
    className,
    dateFormat,
    timeFormat,
    closeOnSelect,
    disabled,
    errorMsg,
    label,
    isInvalid,
    isValidDate,
    onChange,
    formattedDatetime,
    timeConstraints,
    position,
    value,
    style,
  } = props

  const classes = classNames(
    'xtra-datetime-field',
    className
  )

  return (<div className={classes} style={style}>
    {
      !!label &&  
        <XtraInputLabel>
          { label }
        </XtraInputLabel>
    }
    <Datetime
      className={position === 'top' ? 'rdtPickerTop' : undefined}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      value={value}
      timeConstraints={timeConstraints}
      onChange={onChange}
      closeOnSelect={true}
      renderInput={(props, openCalendar, closeCalendar) =>
        <Dropdown.Toggle
          onClick={openCalendar}
          disabled={disabled}
          style={isInvalid ? styles.dateTime.invalid : styles.dateTime.normal}
        >
          {formattedDatetime}
        </Dropdown.Toggle> }
      isValidDate={isValidDate}
    />
    {
      !!isInvalid &&
        <XtraErrorText>
          { errorMsg }
        </XtraErrorText>
    }
  </div>)
}

export default styled(XtraDatetimeField)`
  font-size: 14px;

  .btn {
    &:active {
      outline: none;
      box-shadow: none;
    }

    &:disabled {
      background-color: #DBDEE2;
      opacity: 1;
    }
  }

  .caret {
    float: right;
    margin-top: 8px;
  }

  .rdtPickerTop .rdtPicker {
    top: auto;
    bottom: 100%;
  }
`;
