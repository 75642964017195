import {
  InputGroup,
  Form,
} from 'react-bootstrap';

import styled from 'styled-components';

import XtraInputLabel from './XtraInputLabel';
import XtraErrorText from './XtraErrorText';
import XtraHintText from './XtraHintText';

const StyledFormControl = styled(Form.Control)`
  height: 40px;
  border: 2px solid ${(props) => (props.isinvalid.toString() === 'true' ? '#E2654D' : '#DBDEE2')};
  box-shadow: none;
  letter-spacing: 0.3px;

  :focus {
    border-color: #5C8DF3;
    box-shadow: none;
  }

  :read-only, :disabled {
    background-color: #DBDEE2;
    color: #999;
  }
`;

const XtraTextField = (props) => {
  const {
    className,
    style,
    disabled,
    hintMsg,
    errorMsg,
    label,
    isInvalid,
    id,
    name,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    readOnly,
    type,
    value,
    addon,
    defaultValue,
    onKeyUp,
    onKeyDown,
    componentClass,
    autoFocus,
    min,
    max,
    step,
    size,
    inputRef,
  } = props;

  const renderInput = () => (
    <StyledFormControl
      type={type}
      id={id}
      name={name}
      className={className}
      readOnly={readOnly}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      isinvalid={`${Boolean(isInvalid)}`}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      componentClass={componentClass}
      autoFocus={autoFocus}
      min={min}
      max={max}
      step={step}
      size={size}
      inputRef={inputRef}
    />
  );

  return (
    <div className="xtra-text-field" style={style}>
      {
      !!label
        && (
        <XtraInputLabel>
          { label }
        </XtraInputLabel>
        )
    }
      {
      addon ? (
        <InputGroup
          style={{ width: '100%', marginBottom: '0' }}
        >
          { renderInput() }
          { addon }
        </InputGroup>
      )
        : renderInput()
    }
      {
      isInvalid ? (
        <XtraErrorText>
          {errorMsg}
        </XtraErrorText>
      )
        : (!!hintMsg
        && (
        <XtraHintText style={{ marginTop: '9px' }}>
          {hintMsg}
        </XtraHintText>
        ))
    }
    </div>
  );
};

XtraTextField.defaultProps = {
  type: 'text',
  readOnly: false,
  isInvalid: false,
  disabled: false,
  autoFocus: false,
};

export default XtraTextField;
