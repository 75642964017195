import { ApolloLink } from '@apollo/client';
import map from 'lodash/map';

function createValueSerializer(serializers) {
  return (typeName, value) => {
    if (!serializers[typeName]) return value;
    return serializers[typeName].serializeValue(value);
  };
}

function createVarSetter(operation, varName) {
  return (newVarFunc) => {
    const vars = operation.variables;
    vars[varName] = newVarFunc(vars[varName]);
  };
}

function eachVarDefn(operation, fn) {
  operation.query.definitions.forEach((queryDefn) => {
    switch (queryDefn.kind) {
      case 'OperationDefinition':
        queryDefn.variableDefinitions.forEach((varDefn) => {
          fn(varDefn);
        });
        break;
      default:
        // no-op
        break;
    }
  });
}

function createVariableSerializationLink(serializers) {
  return new ApolloLink((operation, forward) => {
    const serializeValue = createValueSerializer(serializers);

    eachVarDefn(operation, (varDefn) => {
      const varName = varDefn.variable.name.value;
      const varTypeDefn = varDefn.type;

      const setVarValue = createVarSetter(operation, varName);
      const varSubtypeDefn = varTypeDefn.type;

      switch (varTypeDefn.kind) {
        case 'ListType':
          if (varSubtypeDefn.kind !== 'NamedType') return;

          setVarValue((val) => (
            map(val, (item) => (
              serializeValue(varSubtypeDefn.name.value, item)
            ))
          ));
          break;

        case 'NonNullType':
          if (varSubtypeDefn.kind !== 'NamedType') return;

          setVarValue((val) => serializeValue(varSubtypeDefn.name.value, val));
          break;

        case 'NamedType':
          setVarValue((val) => serializeValue(varTypeDefn.name.value, val));
          break;

        default:
          // no-op
          break;
      }
    });
    return forward(operation);
  });
}

export default createVariableSerializationLink;
