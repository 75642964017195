import QueueLink from 'apollo-link-queue';

const offlineLink = new QueueLink();

if (typeof window !== 'undefined') {
  window.addEventListener('offline', () => offlineLink.close());
  window.addEventListener('online', () => offlineLink.open());
}

const createOfflineLink = (): QueueLink => offlineLink;

export default createOfflineLink;
