import { gql } from '@apollo/client';

const setTextFieldMutation = gql`
  mutation setTExtfield(
    $orderAccessKey: String!
    $attendeeId: ID!
    $fieldElementId: ID!
    $value: [String!]!
  ) {
    orderFieldSetTextValues(
      input: {
        orderAccessKey: $orderAccessKey
        attendeeId: $attendeeId
        fieldElementId: $fieldElementId
        value: $value
      }
    ) {
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      clientMutationId
      success
    }
  }
`;
export default setTextFieldMutation;
