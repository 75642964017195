import styled from 'styled-components';

const XtraInputLabel = styled.h5`
  color: #A2AAB3;
  margin-bottom: 14px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 14px;
`;

export default XtraInputLabel;
