import { gql } from '@apollo/client';

const setChoiceFieldMutation = gql`
  mutation orderFieldSetChoiceValues(
    $orderAccessKey: String!
    $attendeeId: ID!
    $fieldElementId: ID!
    $value: [ID!]!
  ) {
    orderFieldSetChoiceValues(
      input: {
        orderAccessKey: $orderAccessKey
        attendeeId: $attendeeId
        fieldElementId: $fieldElementId
        value: $value
      }
    ) {
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      clientMutationId
      success
    }
  }
`;
export default setChoiceFieldMutation;
