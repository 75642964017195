import { gql } from '@apollo/client';

const orderInitiatePayment = gql`
  mutation orderInitiatePayment(
    $orderAccessKey: String!, $locale: LocaleEnum!
  ) {
    orderSetLocale: orderSetLocale(
      input: {
        orderAccessKey: $orderAccessKey,
        newLocale: $locale
      }
    ) {
      success
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
    }
    orderInitiatePayment: orderInitiatePayment(
      input: { orderAccessKey: $orderAccessKey }
    ) {
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      success
      payment {
        status
        uuid
      }
    }
  }
`;

export default orderInitiatePayment;
