import styled from 'styled-components';

const LoadingIconContainer = styled.div<{ marginLeft?: string }>`
  position: relative;
  width: 24px;
  margin: 20px 0;
  display: inline-block;
  height: auto;
  transform: translateY(-8px);
  margin-left: ${({ marginLeft }) => (marginLeft || '0')};
`;

export default LoadingIconContainer;
