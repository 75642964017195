import { gql } from '@apollo/client';

const rsvpOrderLookupOrCreate = gql`
  mutation rsvpOrderLookupOrCreate($rsvpInvitationToken: String!) {
    rsvpOrderLookupOrCreate(
      input: {
        rsvpInvitationToken: $rsvpInvitationToken
      }
    ) {
      order {
        accessKey
        availablePaymentMethods
        plan
        status
        expiresIn
        expiresAt
        regform {
          regformAccessKey
          defaultTicket {
            price {
              currencyCode
            }
          }
        }
        attendees {
          nodes {
            attendeeId
          }
        }
        appliedDiscountCodes {
          nodes {
            discountCode: code
            discountType: __typename
          }
        }
      }
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
    }
  }
`;

export default rsvpOrderLookupOrCreate;
