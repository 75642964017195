import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled, { keyframes } from 'styled-components';

import SyncIcon from 'icons/Sync';
import DoneIcon from 'icons/Done';

import XtraFlexbox from './XtraFlexbox';
import XtraHintText from './XtraHintText';

// Reference: https://www.styled-components.com/docs/api#keyframes
const rotateKeyFrame = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const StyledSyncIcon = styled(SyncIcon)`
  animation: ${rotateKeyFrame} 1.4s linear infinite;
`;

const StyledXtraHintText = styled(XtraHintText)`
  margin-left: 8px;
`;

const XtraLoadingIndicator = ({ loading, success }) => {
  const { t } = useTranslation();

  return (
    <>
      {
        loading && (
          <XtraFlexbox>
            <StyledSyncIcon color="#5C8DF3" />
            <StyledXtraHintText>
              { t('general.saving') }
            </StyledXtraHintText>
          </XtraFlexbox>
        )
      }
      {
        success && !loading && (
          <XtraFlexbox>
            <DoneIcon color="#06AF97" />
            <StyledXtraHintText>
              { t('general.saved') }
            </StyledXtraHintText>
          </XtraFlexbox>
        )
      }
    </>
  );
};

XtraLoadingIndicator.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
};

XtraLoadingIndicator.defaultProps = {
  loading: false,
  success: false,
};

export default XtraLoadingIndicator;
