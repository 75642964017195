import { gql } from '@apollo/client';

const getOrderQuotation = gql`
  query getOrderQuotation($accessKey: ID!, $ticketIds: [ID!]!, $discountCodes: [String!]!, $discountCode: String!) {
    orderByAccessKey(accessKey: $accessKey) {
      discountCode(discountCode: $discountCode) {
        discountCode: code
        isAvailable
        discountOffersAvailable {
          nodes {
            discountType: __typename
            en_description: description @locale(lang: en)
            zh_hk_description: description @locale(lang: zh_hk)
            zh_cn_description: description @locale(lang: zh_cn)
            ko_description: description @locale(lang: ko)
            ja_description: description @locale(lang: ja)
            ru_description: description @locale(lang: ru)
            discountCode
            ... on OrderFixedAmountDiscountOffer {
              discountAmount {
                amount
                currencyCode
              }
            }
            ... on OrderPercentageDiscountOffer {
              discountPercent
              discountPercentOff
              discountMultiplier
            }
            ... on OrderBuyXGetYFreeDiscountOffer {
              buyQuantity
              getQuantity
            }
            limitations {
              ...MinimumQuantityLimitation
              ...MaximumQuantityLimitation
              ...SpecificTicketsLimitation
            }
          }
        }
      }
      quotation(ticketIds: $ticketIds, discountCodes: $discountCodes) {
        actualPrice {
          amount
          currencyCode
        }
        discountedAmount {
          amount
          currencyCode
        }
        applicableDiscountOffers {
          nodes {
            discountCode
          }
        }
      }
      tickets {
        nodes {
          ticketId
          name
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
  fragment MinimumQuantityLimitation on OrderDiscountOfferMinimumQuantityLimitation {
    type
    minimumQuantity
  }
  fragment MaximumQuantityLimitation on OrderDiscountOfferMaximumQuantityLimitation {
    type
    maximumQuantity
  }
  fragment SpecificTicketsLimitation on OrderDiscountOfferSpecificTicketsLimitation {
    type
    specificTickets {
      nodes {
        ticketId
        name
      }
    }
  }
`;

export default getOrderQuotation;
