import { gql } from '@apollo/client';

const orderCancel = gql`
  mutation orderCancel($orderAccessKey: String!) {
    orderCancel(input: { orderAccessKey: $orderAccessKey }) {
      errors {
        type
      }
    }
  }
`;

export default orderCancel;
