/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import PropTypes from 'prop-types';

import classNames from 'classnames';

import styled from 'styled-components';

import XtraInputLabel from './XtraInputLabel';
import XtraErrorText from './XtraErrorText';
import XtraFlexbox from './XtraFlexbox';
import XtraHintText from './XtraHintText';
import XtraToggle from './XtraToggle';

const XtraToggleField = function (props) {
  const {
    className,
    style,
    checked,
    icons,
    label,
    errorMsg,
    hintMsg,
    isInvalid,
    disabled,
    onChange,
  } = props;

  const classes = classNames('xtra-toggle-field', className);

  return (
    <div className={classes} style={style}>
      <XtraFlexbox style={{
        marginTop: '16px',
        marginBottom: '8px',
      }}
      >
        {label ? (
          <XtraInputLabel style={{
            margin: '0 40px 0 0',
          }}
          >
            {label}
          </XtraInputLabel>
        ) : undefined}
        <XtraToggle checked={checked} icons={icons} disabled={disabled} onChange={onChange} />
      </XtraFlexbox>
      {isInvalid ? <XtraErrorText>{errorMsg}</XtraErrorText> : (
        <XtraHintText style={{
          marginTop: '8px',
          fontSize: '14px',
        }}
        >
          {hintMsg}
        </XtraHintText>
      )}
    </div>
  );
};

XtraToggleField.propsTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  checked: PropTypes.bool,
  icons: PropTypes.shape({
    checked: PropTypes.node,
    unchecked: PropTypes.node,
  }),
  label: PropTypes.string,
  errorMsg: PropTypes.node,
  hintMsg: PropTypes.node,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default XtraToggleField;
