import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import classNames from 'classnames';

import XtraHintText from './XtraHintText';
import XtraInputLabel from './XtraInputLabel';
import XtraErrorText from './XtraErrorText';

const reactSelectStyle = (isInvalid) => ({
  container: (provided, state) => ({
    ...provided,
    fontFamily: 'Lato',
    fontSize: '14px',
  }),
  control: (provided, state) => {
    const {
      selectProps,
      isDisabled,
    } = state;
    let style = {
      border: '2px solid #DBDEE2',
      boxShadow: 'none',
      transition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
      ':hover': {
        backgroundColor: '#e6e6e6',
      },
    };
    if (isDisabled) {
      style.backgroundColor = '#DBDEE2';
    }
    if (selectProps.menuIsOpen) {
      style = {
        ...style,
        borderColor: '#DBDEE2',
        backgroundColor: '#e6e6e6',
      };
    }
    if (isInvalid) {
      style.border = '2px solid #E2654D';
      style[':hover'].border = style.border;
    }
    return ({
      ...provided,
      ...style,
    });
  },
  clearIndicator: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  option: (provided, state) => {
    let style = {
      backgroundColor: '#fff',
      ':hover': {
        color: '#fff',
        backgroundColor: '#5C8DF3',
        cursor: 'pointer',
      },
    };
    if (state.isSelected) {
      style = {
        ...style,
        color: '#5C8DF3',
        backgroundColor: '#fff',
      };
    }
    return ({
      ...provided,
      ...style,
    });
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 5,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    border: '2px solid #A2AAB3',
    margin: '4px 0',
    color: '#484848',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    border: '0',
    padding: '3px 5px',
    cursor: 'pointer',
  }),
});

const reactSelectValue = (values, options) => {
  let newValues;
  if (values && !Array.isArray(values)) {
    newValues = [values];
  } else newValues = [];
  return newValues.map((value) => options.find((option) => option.value == value));
};

const XtraSelect = (props) => {
  const {
    id,
    name,
    options,
    value,
    isDisabled,
    isClearable,
    label,
    isMulti,
    isSearchable = true,
    isInvalid,
    onChange,
    errorMsg,
    placeholder,
    style,
    selectStyles,
    className,
    classNamePrefix,
    closeMenuOnSelect,
    openMenuOnFocus,
    noOptionsMessage,
    isOptionDisabled,
    creatable,
    createOptionPosition,
    formatCreateLabel,
    components,
    theme,
  } = props;

  const mainStyles = {
    ...reactSelectStyle(isInvalid),
    ...selectStyles,
  };

  const classes = classNames(
    'xtra-select',
    className,
  );

  const newValue = reactSelectValue(value, options);

  return (
    <div className={classes} style={style}>
      {
      !!label
        && (
        <XtraInputLabel>
          { label }
        </XtraInputLabel>
        )
    }
      {
      creatable
        ? (
          <CreatableSelect
            id={id}
            name={name}
            classNamePrefix={classNamePrefix}
            placeholder={placeholder}
            styles={mainStyles}
            options={options}
            value={newValue}
            onChange={onChange}
            components={components}
            createOptionPosition={createOptionPosition}
            formatCreateLabel={formatCreateLabel}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            closeMenuOnSelect={closeMenuOnSelect}
            openMenuOnFocus={openMenuOnFocus}
            isOptionDisabled={isOptionDisabled}
            noOptionsMessage={noOptionsMessage}
            theme={theme}
          />
        )
        : (
          <Select
            id={id}
            name={name}
            classNamePrefix={classNamePrefix}
            placeholder={placeholder}
            styles={mainStyles}
            options={options}
            value={newValue}
            onChange={onChange}
            components={components}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            closeMenuOnSelect={closeMenuOnSelect}
            openMenuOnFocus={openMenuOnFocus}
            isOptionDisabled={isOptionDisabled}
            noOptionsMessage={noOptionsMessage}
            theme={theme}
          />
        )
    }
      {
      !!isInvalid
        && (
        <XtraErrorText>
          { errorMsg }
        </XtraErrorText>
        )
    }
    </div>
  );
};

export default XtraSelect;
