import styled from 'styled-components';
import XtraButton from 'components/XtraButton';

const ContinueButton = styled(XtraButton)<{ themeColor: string, fontColor: string }>`
  width: 100%;
  max-width: 154px;
  height: auto;
  padding: 12px 2px;
  font-size: 1.5em;
  line-height: 32px;
  transition: all 0.3s;
  background-color: ${({ themeColor }) => themeColor} !important;
  color: ${({ fontColor }) => fontColor};
  border: none;
  font-family: unset;

  &:focus {
    background-color: ${({ themeColor }) => themeColor} !important;
    color: ${({ fontColor }) => fontColor};
    opacity: 0.7 !important;
  }

  &:hover {
    background-color: ${({ themeColor }) => themeColor} !important;
    color: ${({ fontColor }) => fontColor};
    opacity: 0.7 !important;
  }
`;

export default ContinueButton;
