import {
  ProgressBar,
} from 'react-bootstrap';

import styled from 'styled-components';

const XtraProgressBar = styled(ProgressBar)`
  float: left;
  width: 400px;
  height: 16px;
  margin-bottom: 0px;
  box-shadow: none;

  .progress-bar.progress-bar-info {
    background-color: ${({ color, bsStyle }) => {
    if (color) return color;
    switch (bsStyle) {
      case 'danger':
        return '#E2654D';
      default:
        return '#9BDFD5';
    }
  }};
    box-shadow: none;
  }
`;

export default XtraProgressBar;
