import styled from 'styled-components';

import XtraHintText from './XtraHintText';

export default styled(XtraHintText)`
  color: #E2654D;
  font-size: 13px;
  margin-top: 8px;
  letter-spacing: 0.3px;
`;
