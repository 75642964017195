import PropTypes from 'prop-types';

import XtraButton from './XtraButton';

const BS_STYLE = 'primary';
const BTN_COLOR = '#5C8DF3';

const XtraPositiveButton = (props) => {
  const {
    className,
    style,
    type,
    size,
    bsStyle,
    onClick,
    href,
    target,
    disabled,
    active,
    block,
    id,
    children,
  } = props;

  const resolvedBsStyle = bsStyle || BS_STYLE;

  const resolvedStyle = {
    backgroundColor: BTN_COLOR,
    border: '0',
    ...style,
  };

  return (
    <XtraButton
      className={className}
      style={resolvedStyle}
      type={type}
      size={size}
      id={id}
      bsStyle={resolvedBsStyle}
      onClick={onClick}
      href={href}
      target={target}
      disabled={disabled}
      active={active}
      block={block}
    >
      { children }
    </XtraButton>
  );
};

XtraPositiveButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf([
    'button',
    'reset',
    'submit',
  ]),
  size: PropTypes.oneOf([
    'normal',
    'large',
  ]),
  bsStyle: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
  ]),
  id: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  block: PropTypes.bool,
};

XtraPositiveButton.defaultProps = {
  size: 'normal',
  id: undefined,
};

export default XtraPositiveButton;
