import dayjs from 'dayjs';

const DateTimeTransformer = {
  parseValue(value) {
    return value ? dayjs(value) : value;
  },
  serialize(value) {
    return value ? value.toISOString() : value;
  },
};

export default DateTimeTransformer;
