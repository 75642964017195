import classNames from 'classnames';

import { v1 as uuidv1 } from 'uuid';

import styled from 'styled-components';

import XtraText from './XtraText';

const StyledLabel = styled.label`
  width: 100%;
`;

const XtraCheckbox = (props) => {
  const {
    className,
    style,
    id,
    children,
    checked,
    defaultChecked,
    disabled,
    onChange,
  } = props;

  const classes = classNames(
    'xtra-checkbox',
    className,
  );

  const resolvedId = id || uuidv1();

  return (
    <div
      className={classes}
      style={style}
    >
      <input
        type="checkbox"
        id={resolvedId}
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={onChange}
      />
      <StyledLabel htmlFor={resolvedId}>
        <XtraText>
          { children }
        </XtraText>
      </StyledLabel>
    </div>
  );
};

const StyledXtraCheckbox = styled(XtraCheckbox)<{
  verticalSpacing: string | number;
  transverseSpacing: boolean;
  borderSize: string;
  backgroundColor: string;
}>`
  position: relative;
  margin: ${({ verticalSpacing }) => (verticalSpacing ? `${verticalSpacing}em 0` : '0')};
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    display: inline;
    line-height: 24px;
    vertical-align: top;
    clear: both;
    padding-left: 1px;
    margin: 0;

    &:not(:empty) {
      padding-left: 0.75em;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      ${({ transverseSpacing }) => (!transverseSpacing ? 'left:0;' : '')}
      top: 4.5px;
    }

    &:before {
      width: 15px;
      height: 15px;
      background: #fff;
      border: ${({ borderSize }) => (borderSize || '0.5px')} solid #484848;
      border-radius: 0.125em;
      cursor: pointer;
      transition: background 0.3s;
    }
  }

  input[type=checkbox] {
    outline: 0;
    opacity: 0;
    width: 15px;
    margin: 0;

    &:focus {
      & + label:before {
        border: 2px ${({ backgroundColor }) => (backgroundColor || '#5C8DF3')} solid;
      }
    }

    &:checked {
      & + label:before {
        background: ${({ backgroundColor }) => (backgroundColor || '#5C8DF3')};
        border-color: ${({ backgroundColor }) => (backgroundColor || '#5C8DF3')};
      }

      & + label:after {
        transform: translate(2px, 3.26923px) rotate(-45deg);
        width: 11px;
        height: 5.5px;
        border: 0.125em solid #fff;
        border-top-style: none;
        border-right-style: none;
      }

      &:disabled + label:before {
        background: rgba(0, 0, 0, 0.26);
      }
    }

    &:disabled + label:before {
      border-color: rgba(0, 0, 0, 0.26);
    }
  }
`;

export default StyledXtraCheckbox;
