import { gql } from '@apollo/client';

const createOrder = gql`
  mutation addorder($regformAccessKey: String!, $attendeesCount: Int!) {
    registrationOrderCreate(
      input: {
        regformAccessKey: $regformAccessKey
        attendeesCount: $attendeesCount
      }
    ) {
      order {
        accessKey
        availablePaymentMethods
        plan
        status
        expiresIn
        expiresAt
        regform {
          regformAccessKey
          includeTicketing
          accessCodeEnabled
          defaultTicket {
            price {
              currencyCode
            }
          }
        }
      }
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
    }
  }
`;
export default createOrder;
