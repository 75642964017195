import { gql } from '@apollo/client';
import orderTicketFragment from './orderTicketFragment';

export default gql`
  query getAttendeeByAccessKey(
    $accessKey: ID!
    $en: Boolean!
    $zh_hk: Boolean!
    $zh_cn: Boolean!
    $ja: Boolean!
    $ko: Boolean!
    $ru: Boolean!
  ) {
    orderByAccessKey(accessKey: $accessKey) {
      attendees {
        nodes {
          attendeeId
          attendeeTickets {
            en: nodes @locale(lang: en) @include(if: $en){
              ...TicketNodes
            }
            zh_hk: nodes @locale(lang: zh_hk) @include(if: $zh_hk){
              ...TicketNodes
            }
            zh_cn: nodes @locale(lang: zh_cn) @include(if: $zh_cn){
              ...TicketNodes
            }
            ja: nodes @locale(lang: ja) @include(if: $ja){
              ...TicketNodes
            }
            ko: nodes @locale(lang: ko) @include(if: $ko){
              ...TicketNodes
            }
            ru: nodes @locale(lang: ru) @include(if: $ru){
              ...TicketNodes
            }
          }
          mainForm {
            pages {
              nodes {
                elements {
                  nodes {
                    id: elementId
                    ... on OrderAttendeeFormGenericFieldElement {
                      element {
                        typeName: __typename
                      }
                      value {
                        ... on OrderAttendeeTextFieldValue {
                          value
                        }
                        ... on OrderAttendeeBooleanFieldValue {
                          value
                        }
                        ... on OrderAttendeeTextChoiceFieldValue {
                          value
                        }
                        ... on OrderAttendeeFileFieldValue {
                          files {
                            contentType
                            filename
                            fingerprint
                            size
                            url
                          }
                        }
                        ... on OrderAttendeeChoiceFieldValue {
                          chosenChoices {
                            nodes {
                              choiceId
                            }
                          }
                        }
                      }
                    }
                    ... on OrderAttendeeFormGenericElement {
                      element {
                        typeName: __typename
                      }
                    }
                  }
                }
              }
            }
          }
          firstName
          lastName
          email
          secondaryEmails {
            edges {
              node {
                email
              }
            }
          }
          phone
          salutation
          company
          jobPosition
          industry
          country
          city
        }
      }
    }
  }
  ${orderTicketFragment}
`;
