import { gql } from '@apollo/client';
import orderTicketFragment from './orderTicketFragment';

const mutationStringWrapper = (
  orderAccessKey,
  attendeeId,
  ticketId,
  index,
  languageList,
) => (
  `orderAttendeeAddTicket${index}: orderAttendeeAddTicket(
    input: {
      orderAccessKey: "${orderAccessKey}"
      attendeeId: "${attendeeId}"
      ticketId: "${ticketId}"
    }
  ) {
    success
    errors {
      type
      message
      path
    }
    attendee {
      attendeeId
      attendeeTickets {
        en: nodes @locale(lang: en) @include(if: ${languageList.includes('en')}){
          ...TicketNodes
        }
        zh_hk: nodes @locale(lang: zh_hk) @include(if: ${languageList.includes('zh_hk')}){
          ...TicketNodes
        }
        zh_cn: nodes @locale(lang: zh_cn) @include(if: ${languageList.includes('zh_cn')}){
          ...TicketNodes
        }
        ja: nodes @locale(lang: ja) @include(if: ${languageList.includes('ja')}){
          ...TicketNodes
        }
        ko: nodes @locale(lang: ko) @include(if: ${languageList.includes('ko')}){
          ...TicketNodes
        }
        ru: nodes @locale(lang: ru) @include(if: ${languageList.includes('ru')}){
          ...TicketNodes
        }
      }
      mainForm {
        pages {
          nodes {
            elements {
              nodes {
                id: elementId
                ... on OrderAttendeeFormGenericFieldElement {
                  element {
                    typeName: __typename
                  }
                }
                ... on OrderAttendeeFormGenericElement {
                  element {
                    typeName: __typename
                  }
                }
              }
            }
          }
        }
      }
      firstName
      lastName
      email
      secondaryEmails {
        edges {
          node {
            email
          }
        }
      }
      phone
      salutation
      company
      jobPosition
      industry
      country
      city
    }
  }
`);

const orderAttendeeAddTicket = (
  orderAccessKey,
  attendeeIds,
  ticketIds,
  languageList,
) => {
  let mutationString = '';
  attendeeIds.forEach((eachAttendeeId, index) => {
    mutationString += mutationStringWrapper(
      orderAccessKey,
      eachAttendeeId,
      ticketIds[index],
      index,
      languageList,
    );
  });
  mutationString = `
    mutation orderAttendeeAddTicket {
      ${mutationString}
    }
    ${orderTicketFragment}
  `;
  return gql(mutationString);
};

export default orderAttendeeAddTicket;
