import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import isFunction from 'lodash/fp/isFunction';

import {
  Modal,
} from 'react-bootstrap';

import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import XtraButton from './XtraButton';
import XtraH3 from './XtraH3';
import XtraPositiveButton from './XtraPositiveButton';

class XtraModal extends PureComponent {
  renderProp = (propValue) => (isFunction(propValue) ? propValue() : propValue)

  render() {
    const { t } = this.props;
    const {
      className,
      title,
      positiveBtnHref,
      positiveBtnTarget,
      positiveBtnLabel = t('general.confirm'),
      positiveBtnOnClick,
      positiveBtnDisabled,
      cancelBtnLabel = t('general.cancel'),
      closeButton,
      onHide,
      onKeyPress,
      onKeyUp,
      children,
      ...props
    } = this.props;

    const classes = classNames(
      'xtra-modal',
      className,
    );

    const renderedCancelBtnLabel = this.renderProp(cancelBtnLabel);
    const renderedPositiveBtnLabel = this.renderProp(positiveBtnLabel);

    return (
      <Modal
        className={classes}
        onHide={onHide}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        {...props}
      >
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>
            <XtraH3>
              { title }
            </XtraH3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { children }
        </Modal.Body>
        <Modal.Footer>
          {
            renderedCancelBtnLabel != null
              ? (
                <XtraButton onClick={onHide} id="modal-cancel-button">
                  { renderedCancelBtnLabel }
                </XtraButton>
              )
              : <span />
          }
          {
            renderedPositiveBtnLabel != null
              && (
              <XtraPositiveButton
                onClick={positiveBtnOnClick}
                id="modal-positive-button"
                disabled={positiveBtnDisabled}
                href={positiveBtnHref}
                target={positiveBtnTarget}
              >
                { renderedPositiveBtnLabel }
              </XtraPositiveButton>
              )
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

XtraModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  positiveBtnHref: PropTypes.string,
  positiveBtnTarget: PropTypes.string,
  positiveBtnLabel: PropTypes.oneOfType([
    PropTypes.func, PropTypes.node,
  ]),
  positiveBtnOnClick: PropTypes.func,
  positiveBtnDisabled: PropTypes.bool,
  cancelBtnLabel: PropTypes.oneOfType([
    PropTypes.func, PropTypes.node,
  ]),
  closeButton: PropTypes.bool,
  onHide: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  children: PropTypes.any,
};

export default styled(withTranslation()(XtraModal))`
  font-family: Lato;
  font-size: 16px;
  border-radius: 4px;

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: none;

    &:before, &:after {
      content: none;
    }
  }
`;
