import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

import XtraErrorText from 'components/XtraErrorText';
import styled from 'styled-components';

const StyledRadio = styled(Radio)`
  padding: 0 12px !important;

  svg {
    width: 16px;
  }
`;

const StyledRadioLabel = styled(FormControlLabel)`
  span {
    font-family: Lato;
    font-size: 16px;
  }
`;

export const COLOR_STYLES = ['default', 'primary', 'secondary'];

const XtraRadioGroup = ({
  className,
  disabled,
  options,
  value,
  onChange,
  color,
  isInvalid,
  errorMsg,
}) => {
  const classes = classNames('xtra-radio-group', className);
  return (
    <div>
      <RadioGroup
        className={classes}
        value={value}
        onChange={onChange}
      >
        {
          options.map((option) => (
            <StyledRadioLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={disabled}
              control={(
                <StyledRadio
                  color={color}
                />
              )}
            />
          ))
        }
      </RadioGroup>
      {
        isInvalid
          && (
          <XtraErrorText>
            { errorMsg }
          </XtraErrorText>
          )
      }
    </div>
  );
};

XtraRadioGroup.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(COLOR_STYLES),
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.string.isRequired,
};

XtraRadioGroup.defaultProps = {
  className: '',
  disabled: false,
  color: COLOR_STYLES[0],
  isInvalid: false,
  errorMsg: '',
};

export default XtraRadioGroup;
