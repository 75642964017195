import { ApolloLink } from '@apollo/client';
import { onError } from 'apollo-link-error';

const createErrorLink = (): ApolloLink => onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }
  // eslint-disable-next-line no-console
  if (networkError) console.error(`[Network error]: ${networkError}`);
}) as unknown as ApolloLink;

export default createErrorLink;
