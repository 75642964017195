import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import isFunction from 'lodash/fp/isFunction';

import {
  Modal,
} from 'react-bootstrap';

import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import XtraButton from './XtraButton';
import XtraH3 from './XtraH3';
import XtraPositiveButton from './XtraPositiveButton';

class XtraMultipleStepsModal extends PureComponent {
  renderProp = (propValue) => {
    if (isFunction(propValue)) return propValue();
    return propValue;
  };

  render() {
    const { t } = this.props;
    const {
      className,
      title,
      positiveBtnHref,
      positiveBtnTarget,
      positiveBtnLabel = t('general.confirm'),
      positiveBtnOnClick,
      positiveBtnDisabled,
      leftBtnLabel = t('general.cancel'),
      leftBtnOnClick,
      closeButton,
      onHide,
      onKeyPress,
      children,
      ...props
    } = this.props;
    const classes = classNames('xtra-modal', className);
    const renderedLeftBtnLabel = this.renderProp(leftBtnLabel);
    const renderedPositiveBtnLabel = this.renderProp(positiveBtnLabel);

    const onCLickAction = leftBtnOnClick || onHide;
    const showLeftBtnLabel = renderedLeftBtnLabel != null;
    const showPositiveBtnLabel = renderedPositiveBtnLabel != null;

    return (
      <Modal
        className={classes}
        onHide={onHide}
        onKeyPress={onKeyPress}
        {...props}
      >
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>
            <XtraH3>
              { title }
            </XtraH3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { children }
        </Modal.Body>
        <Modal.Footer>
          {showLeftBtnLabel ? (
            <XtraButton onClick={onCLickAction}>
              { renderedLeftBtnLabel }
            </XtraButton>
          ) : (
            <span />
          )}
          {showPositiveBtnLabel && (
          <XtraPositiveButton
            onClick={positiveBtnOnClick}
            disabled={positiveBtnDisabled}
            href={positiveBtnHref}
            target={positiveBtnTarget}
          >
            { renderedPositiveBtnLabel }
          </XtraPositiveButton>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

XtraMultipleStepsModal.defaultProps = {
  className: null,
  title: null,
  positiveBtnHref: null,
  positiveBtnTarget: null,
  positiveBtnOnClick: null,
  positiveBtnDisabled: false,
  leftBtnOnClick: null,
  closeButton: true,
  onHide: null,
  onKeyPress: null,
  children: null,
};

XtraMultipleStepsModal.propTypes = {
  className: PropTypes.string,
  positiveBtnHref: PropTypes.string,
  positiveBtnTarget: PropTypes.string,
  positiveBtnLabel: PropTypes.oneOfType([
    PropTypes.func, PropTypes.node,
  ]),
  positiveBtnOnClick: PropTypes.func,
  positiveBtnDisabled: PropTypes.bool,
  leftBtnLabel: PropTypes.oneOfType([
    PropTypes.func, PropTypes.node,
  ]),
  leftBtnOnClick: PropTypes.func,
  closeButton: PropTypes.bool,
  onHide: PropTypes.func,
  onKeyPress: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,
};

export default styled(withTranslation()(XtraMultipleStepsModal))`
  font-family: Lato;
  font-size: 16px;
  border-radius: 4px;

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: none;

    &::before, &::after {
      content: none;
    }
  }
`;
