import styled from 'styled-components';

interface XtraFlexboxProps {
  direction?: string;
  wrap?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
}

const XtraFlexbox = styled.div<XtraFlexboxProps>`
display: flex;
flex-direction: ${({ direction }) => direction};
flex-wrap: ${({ wrap }) => wrap};
justify-content: ${({ justifyContent }) => justifyContent};
align-items: ${({ alignItems }) => alignItems};
align-content: ${({ alignContent }) => alignContent};\
`;

XtraFlexbox.defaultProps = {
  direction: 'row',
  wrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignContent: 'stretch',
};

export default XtraFlexbox;
