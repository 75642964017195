import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const EditorSubmitButton = styled(Button)`
  width: 100%;
  max-width: 800px;
  margin-top: 8px;
`;

export default EditorSubmitButton;
