import { gql } from '@apollo/client';

const getAppliedDiscountCodes = gql`
  query getAppliedDiscountCodes($accessKey: ID!) {
    orderByAccessKey(accessKey: $accessKey) {
      appliedDiscountCodes {
        nodes {
          discountCode: code
          discountType: __typename
        }
      }
    }
  }
`;

export default getAppliedDiscountCodes;
