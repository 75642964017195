
import PropTypes from 'prop-types';

// TODO: import font-awesome in webpack instead of using the global one to render icon

const XtraFAIcon = ({ className, name }) => (
  <i className={`${className} fa fa-${name}`} />
);

XtraFAIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

XtraFAIcon.defaultProps = {
  className: '',
};

export default XtraFAIcon;
