import { forwardRef, memo } from 'react';
import XtraSvgIcon from 'components/XtraSvgIcon';

// Referenece: https://github.com/mui-org/material-ui/blob/master/packages/material-ui-icons/src/utils/createSvgIcon.js
export default function createSvgIcon(path, displayName, options = {}) {
  const {
    width, height, fontSize, viewBox,
  } = options;
  const Component = memo(
    forwardRef((props, ref) => (
      <XtraSvgIcon
        width={width}
        height={height}
        fontSize={fontSize}
        viewBox={viewBox}
        ref={ref}
        {...props}
      >
        { path }
      </XtraSvgIcon>
    )),
  );

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `${displayName}Icon`;
  }

  return Component;
}
