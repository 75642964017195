import { createContext } from 'react';

const XtraGraphContext = createContext({
  errors: [],
  setErrors: () => {},
  mutationLoading: false,
  setMutationLoading: () => {},
  mutationSuccess: false,
  setMutationSuccess: () => {},
});

export default XtraGraphContext;
