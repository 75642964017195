import { gql } from '@apollo/client';

const fetchThankYouPageQuery = gql`
  query fetchThankYouPageQuery(
    $orderAccessKey: ID!
    $en: Boolean!
    $zh_hk: Boolean!
    $zh_cn: Boolean!
    $ja: Boolean!
    $ko: Boolean!
    $ru: Boolean!
  ){
    orderByAccessKey(accessKey: $orderAccessKey){
      thankYouPageView {
        pages {
          nodes {
            elements {
              en: nodes @locale(lang: en) @include(if: $en) {
                ...ThankYouPageElementNodes
              }
              zh_hk: nodes @locale(lang: zh_hk) @include(if: $zh_hk) {
                ...ThankYouPageElementNodes
              }
              zh_cn: nodes @locale(lang: zh_cn) @include(if: $zh_cn) {
                ...ThankYouPageElementNodes
              }
              ja: nodes @locale(lang: ja) @include(if: $ja) {
                ...ThankYouPageElementNodes
              }
              ko: nodes @locale(lang: ko) @include(if: $ko) {
                ...ThankYouPageElementNodes
              }
              ru: nodes @locale(lang: ru) @include(if: $ru) {
                ...ThankYouPageElementNodes
              }
            }
          }
        }
      }
    }
  }
  fragment ThankYouPageElementNodes on RegformElement {
    id
    ... on RegformBuilderObject {
      typeName: __typename
    }
    ... on RegformImageElement {
      url: imageUrl
    }
    ... on RegformTextElement {
      html
    }
  }
`;

export default fetchThankYouPageQuery;
