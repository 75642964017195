import { gql } from '@apollo/client';

const orderOfflinePaymentConfirmMutation = gql`
  mutation orderOfflinePaymentConfirm(
    $orderAccessKey: String!,
    $paymentNonce: String!
  ) {
    orderOfflinePaymentConfirm(
      input: { 
        orderAccessKey: $orderAccessKey,
        paymentNonce: $paymentNonce
      }
    ) {
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      success
      order {
        status
      }
    }
  }
`;

export default orderOfflinePaymentConfirmMutation;
