import { gql } from '@apollo/client';

const fetchLanuageListQuery = gql`
  query fetchLanuageListQuery($accessKey: ID!) {
    orderByAccessKey(accessKey: $accessKey) {
      regform {
        locales
        status
      }
    }
  }
`;

export default fetchLanuageListQuery;
