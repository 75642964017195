import { gql } from '@apollo/client';

const applyDiscountCodeMutation = gql`
  mutation applyDiscountCode($orderAccessKey: String!, $discountCode: String!) {
    orderApplyDiscountCode(input: {
      orderAccessKey: $orderAccessKey,
      discountCode: $discountCode,
    }) {
      success
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
    }
  }
`;
export default applyDiscountCodeMutation;
