import { gql } from '@apollo/client';

const setFileFieldMutation = gql`
  mutation setFileFieldMutation(
    $orderAccessKey: String!
    $attendeeId: ID!
    $fieldElementId: ID!
    $value: Upload
  ) {
    orderFieldSetFileValue(
      input: {
        orderAccessKey: $orderAccessKey
        attendeeId: $attendeeId
        fieldElementId: $fieldElementId
        value: $value
      }
    ) {
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
      clientMutationId
      success
      affectedElements {
        ...on OrderAttendeeFormFieldElement {
          value {
            ... on OrderAttendeeFileFieldValue {
              files {
                contentType
                filename
                fingerprint
                size
                url
              }
            }
          }
        }
      }
    }
  }
`;
export default setFileFieldMutation;
