import { gql } from '@apollo/client';

const fetchLandingPageQuery = gql`
  query getorderByAccessKey(
    $accessKey: ID!
    $published: Boolean!
    $en: Boolean!
    $zh_hk: Boolean!
    $zh_cn: Boolean!
    $ja: Boolean!
    $ko: Boolean!
    $ru: Boolean!
  ) {
    orderByAccessKey(accessKey: $accessKey) {
      publishedLandingView @include(if: $published) {
        pages {
          nodes {
            elements {
              en: nodes @locale(lang: en) @include(if: $en) {
                ...LandingElementNodes
              }
              zh_hk: nodes @locale(lang: zh_hk) @include(if: $zh_hk) {
                ...LandingElementNodes
              }
              zh_cn: nodes @locale(lang: zh_cn) @include(if: $zh_cn) {
                ...LandingElementNodes
              }
              ja: nodes @locale(lang: ja) @include(if: $ja) {
                ...LandingElementNodes
              }
              ko: nodes @locale(lang: ko) @include(if: $ko) {
                ...LandingElementNodes
              }
              ru: nodes @locale(lang: ru) @include(if: $ru) {
                ...LandingElementNodes
              }
            }
          }
        }
      }
      unpublishedLandingView @skip(if: $published) {
        pages {
          nodes {
            elements {
              en: nodes @locale(lang: en) @include(if: $en){
                ...LandingElementNodes
              }
              zh_hk: nodes @locale(lang: zh_hk) @include(if: $zh_hk){
                ...LandingElementNodes
              }
              zh_cn: nodes @locale(lang: zh_cn) @include(if: $zh_cn){
                ...LandingElementNodes
              }
              ja: nodes @locale(lang: ja) @include(if: $ja){
                ...LandingElementNodes
              }
              ko: nodes @locale(lang: ko) @include(if: $ko){
                ...LandingElementNodes
              }
              ru: nodes @locale(lang: ru) @include(if: $ru){
                ...LandingElementNodes
              }
            }
          }
        }
      }
    }
  }

  fragment LandingElementNodes on RegformBuilderObject {
    id
    ... on RegformBuilderObject {
      typeName: __typename
    }
    ... on RegformImageElement {
      url: imageUrl
    }
    ... on RegformTextElement {
      html
    }
  }
`;

export default fetchLandingPageQuery;
