import CircularProgress from '@material-ui/core/CircularProgress';

import styled from 'styled-components';

import red from '@material-ui/core/colors/red';

import XtraFlexbox from './XtraFlexbox';
import XtraH3 from './XtraH3';

const StyledFlexBox = styled(XtraFlexbox)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  z-index: 10;
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

const CicularProcessContainer = styled.div`
  text-align: center;
  margin: auto;
  color: #484848;
`;

const XtraLoadingOverlay = (props) => {
  const {
    className,
    isLoading,
    loadingMsg,
    size,
    thickness,
    color,
  } = props;

  return (
    <StyledFlexBox
      className={className}
      show={isLoading}
      direction="column"
    >
      <CicularProcessContainer>
        <CircularProgress
          className="loading"
          size={size || 75}
          thickness={thickness || 3}
          style={{ color: color || red[700] }}
        />
        <XtraH3>{loadingMsg}</XtraH3>
      </CicularProcessContainer>
    </StyledFlexBox>
  );
};

export default XtraLoadingOverlay;
