import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverlayTrigger } from 'react-bootstrap';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import XtraButton from './XtraButton';
import XtraTooltip from './XtraTooltip';

const ComponentContainer = styled.div`
  position: relative
`;

const FlowContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 2px solid #DBDEE2;
`;

const StyledInput = styled.input`
  flex: 1;
  border: 0;
  outline: none;
  padding: 0 16px;
`;

const StyledButton = styled(XtraButton)`
  border: 0;
  border-radius: 0;
  border-left: 2px solid #DBDEE2;
  font-weight: 700;
  background-color: #F4F8FF;
  padding: 9px;

  &:hover, &:active, &&:focus {
    background-color: #F4F8FF;
    border-left-color: #DBDEE2;
    box-shadow: none;
  }
`;

const CopyText = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #484848;
`;

const XtraCopyToClipboard = (props) => {
  const { text, onCopy, showCopyText } = props;
  const { t } = useTranslation();

  return (
    <ComponentContainer>
      <FlowContainer>
        <StyledInput
          type="text"
          defaultValue={text}
          readOnly
        />
        <OverlayTrigger
          placement="bottom"
          trigger="click"
          overlay={(
            <XtraTooltip>
              { t('general.copied') }
            </XtraTooltip>
          )}
        >
          {({ ref, ...triggerHandler }) => (
            <CopyToClipboard onCopy={onCopy} text={text}>
              <StyledButton size="large" {...triggerHandler}>
                <FileCopyOutlinedIcon ref={ref} />
                {showCopyText && (
                <CopyText>
                  { t('general.copy') }
                </CopyText>
                )}
              </StyledButton>
            </CopyToClipboard>
          )}
        </OverlayTrigger>
      </FlowContainer>
    </ComponentContainer>
  );
};

XtraCopyToClipboard.propTypes = {
  text: PropTypes.string,
  onCopy: PropTypes.func,
  showCopyText: PropTypes.bool,
};

export default XtraCopyToClipboard;
