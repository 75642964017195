import { gql } from '@apollo/client';

const orderFinalize = gql`
  mutation orderFinalize($orderAccessKey: String!) {
    orderFinalize(input: { orderAccessKey: $orderAccessKey }) {
      success
      errors {
        details {
          key
          value
        }
        message
        path
        type
      }
    }
  }
`;

export default orderFinalize;
